import React from 'react'

import BreadCrumb from '../components/breadCrumb/index'
import Layout from '../components/layout'
import SEO from '../components/seo'
import WorkedWithText from '../components/workedWith/workedWithText'
import ThoughtsOn from '../components/thoughtsOn/index'
import Awards from '../components/awards/index'
import Training from '../components/training/index'
import Feedback from '../components/feedback/index'


const crumbs = [
  {
    depth: '1',
    url: '/',
    title: 'Home',
    styles: '',
  },
]

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        keywords={[`UX`, `Freelance`, `Designer`, `Researcher`]}
        title="About"
      />
      <BreadCrumb crumbs={crumbs} />
      <section className="flex flex-col md:flex-row items-center">
        <section id="about-summary" className="mb-8">
          <h2>About</h2>
          <h3>
            My name is Emily &amp; I am a designer who specialises in user experience.
          </h3>
          <p className="mb-5">
            I have 6 years experience working in digital, as a designer. This has included both agency and also in-house. I’ve created apps for a range of businesses, redesigned three bank websites and led the UX for children’s games and apps at the BBC. With every project, I am always thoughtful in my approach, showing consideration and understanding for the needs of people.          </p>
          <p className="mb-5">
            I studied Graphic Design at Central St Martins in London, where I specialised in interaction design. It was here where I began my fascination with how people interact with design, from how they use a book to how they use a website. Both forms of interaction taught the need for products to be communicated and understood effectively by their users. Constant discovery and learning more about people is what I enjoy the most about now working as a user experience designer.
          </p>
          <p className="mb-5">
            Throughout my career, I have also focused on developing my soft skills, including wellbeing resilience, presenting and leadership. I am now a Mental Health First Aider and recently completed the  BBC's Women into Leadership programme.
          </p>
          <p className="mb-5">
            I’m passionate about designing solutions that work for people and also helping teams learn about how they can be more effective, collaborative and user-centric.
          </p>
          <hr className="mb-12 mt-12" />

        </section>
      </section>
      <WorkedWithText />
      <hr className="mb-12 mt-12" />

      <Awards />
      <Training />
      <hr className="mb-12 mt-12" />

      <Feedback />
      <hr className="mb-12 mt-12" />

      <ThoughtsOn />
    </Layout>
  )
}

export default AboutPage
