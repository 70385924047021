import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'


const Awards = () => {

  const accreditations = useStaticQuery(graphql`
    query {
      allContentfulAwards {
        edges {
          node {
            id
            title
            childContentfulAwardsDescriptionTextNode {
              description
            }
            image {
              title
              description
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const credits = accreditations.allContentfulAwards.edges.map(credit => {
    return (
      <section className="flex flex-wrap mb-8" id={credit.node.id} key={credit.node.id}>
        <div className="sm:w-full md:w-3/12 lg:w-1/6 m-auto">
          <div className="px-2 rounded-full flex items-center justify-center border-2 border-gray-900 h-24 w-24">
            <img alt={credit.node.image.description} className="object-scale-down p-1" src={credit.node.image.file.url} />
          </div>
        </div>
        <div className="sm:w-full md:w-9/12 lg:w-5/6 mt-8 md:mt-0">
          <p className="font-bold">{credit.node.title}</p>
          {credit.node.childContentfulAwardsDescriptionTextNode.description}
        </div>
      </section>
    )
  })

  return (
    <section className="mb-20" id="awards">
      <h3 className="text-3xl font-bold inline-block mb-12">Awards</h3>
      {credits}
    </section>
  )
}

export default Awards
