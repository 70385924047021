import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'


const workedWithText = () => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const clients = useStaticQuery(graphql`
    query {
      allContentfulCompaniesIveWorkedWith(sort:{ fields: datesWorkedAtCompany, order: DESC }) {
        edges {
          node {
            id
            companyName
            datesWorkedAtCompany
            jobRole
            logo {
              title
              description 
              
              file {
                url
              }
            }
            childContentfulCompaniesIveWorkedWithDescriptionTextNode {
              description
            }
          }
        }
      }
    }
  `)

  const clientInfo = clients.allContentfulCompaniesIveWorkedWith.edges.map(client => {
    return (
      <section className="sm:w-2/3 md:w-full md:mr-8 mb-20" id={client.node.companyName} key={client.node.id}>
        <div className="mb-3">
          <img alt={client.node.logo.description} className="h-8" src={client.node.logo.file.url} />
        </div>
        <h4 className="text-2xl font-bold mb-2">
          {client.node.companyName}  ({client.node.datesWorkedAtCompany})
        </h4>
        <h5 className="mb-2 text-xl font-semibold">
          {client.node.jobRole}
        </h5>
        {client.node.childContentfulCompaniesIveWorkedWithDescriptionTextNode ? client.node.childContentfulCompaniesIveWorkedWithDescriptionTextNode.description : null}
      </section>
    )
  })

  return (
    <section id="client-list">
      <h3 className="text-3xl font-bold inline-block mb-12">Where I've worked</h3>
      {clientInfo}
    </section>

  )
}

export default workedWithText