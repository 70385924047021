import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// const trainingCourses = [
//   {
//     key: 1,
//     title: `Mental Health First Aider`,
//     content:
//       `I am a trained Mental Health First Aider and since being qualified have supported staff through a variety of situations in the workplace. I consistently look for opportunities to educate businesses on the topic of mental wellbeing.`,
//   },
//   {
//     key: 2,
//     title: `The Hilda Matheson Programme - Women into Leadership`,
//     content:
//       `Whilst at the BBC, I was selected for the 'Hilda Matheson Programme', which was focused towards helping and empowering women in the workplace to achieve their career goals, I am now and active member of the this community.`,
//   },
// ]

const Training = () => {

  const trainingCourses = useStaticQuery(graphql`
    query {
      allContentfulTraining {
        edges {
          node {
            id
            title
            childContentfulTrainingDescriptionTextNode {
              description
            }
            image {
              title
              description
              file {
                url
              }
            }
          }
        }
      }
    }
    `)

  const courses = trainingCourses.allContentfulTraining.edges.map(course => {
    return (
      <section className="flex flex-wrap mb-8" id={course.node.id} key={course.node.id}>
        <div className="sm:w-full md:w-3/12 lg:w-1/6 m-auto">
          <div className="px-2 rounded-full flex items-center justify-center border-2 border-gray-900 h-24 w-24">
            <img alt={course.node.image.description} className="object-scale-down p-1" src={course.node.image.file.url} />
          </div>
        </div>
        <div className="sm:w-full md:w-9/12 lg:w-5/6 mt-8 md:mt-0">
          <p className="font-bold">{course.node.title}</p>
          {course.node.childContentfulTrainingDescriptionTextNode.description}
        </div>
      </section>
    )
  })

  return (
    <section className="mb-20" id="extra-training">
      <h3 className="text-3xl font-bold inline-block mb-12">Extra training</h3>
      {courses}
    </section>
  )
}

export default Training
