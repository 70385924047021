import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const Feedback = () => {
  const reviews = useStaticQuery(graphql`
  query {
    allContentfulTestimonial {
      edges {
        node {
          title {
            title
          }
          author
          id
        }
      }
    }
  }
`)
  const reviewData = reviews.allContentfulTestimonial.edges.map(review => {
    return (
      <blockquote
        key={review.node.id}
        className="border-gray-900 font-serif mb-12"
      >
        <p className="italic">"{review.node.title.title}"</p>
        <p className="text-lg font-bold mb-1">{review.node.author}</p>
      </blockquote>
    )
  })

  return (
    <section id="feedback" className="mb-20">
      <h3 className="text-3xl font-bold inline-block mb-12">Testimonials</h3>
      {reviewData}
    </section>
  )
}

export default Feedback
